import gql from 'graphql-tag';

export default gql`
  mutation createStripePaymentIntent($source: String!, $transactionId: ID!) {
    createStripePaymentIntent(source: $source, transactionId: $transactionId) {
      success
      transaction {
        activeRecordId
      }
      redirectUrl
    }
  }
`;
